const NextI18Next = require('next-i18next').default;
const path = require('path');

const localePath =
  typeof window === 'undefined' ? path.resolve('./public/static/locales') : '/static/locales';

module.exports = new NextI18Next({

  otherLanguages: ['pg_BR', 'it_IT', 'sp_SP', 'en_UK', 'pl_PL', 'de_DE', 'tr_TR', 'ar_EG', 'en_US', 'fr_FR', 'ar_MA', 'ar_SA', 'ar_DZ'],

  localePath:
    typeof window === 'undefined' ? path.resolve('./public/static/locales') : '/static/locales',
  shallowRender: true,
  defaultLanguage: 'en_US',
  browserLanguageDetection: true,
  nsSeparator: false,
  serverLanguageDetection: true,
  backend: {
    loadPath: `${localePath}/{{lng}}/{{lng}}.json`,
  },
  detection: {
    order: ['path', 'querystring', 'cookie'],
    caches: ['cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
  localeStructure: '{{lng}}',
});
