module.exports = {
  NEXT_PUBLIC_BASE_URL_V1: "https://products.prometeon.com/api/v1/",
  NEXT_PUBLIC_DEALERS_URL_V1:
    "https://dealer-locator.prometeon.com/api/v1/dealers/",
  NEXT_PUBLIC_DIRECTUS: "https://directus-dev.prometeon.com/website/items/",
  WS_CATALOG:"https://ws-catalog.prometeon.com/b2cwscatalog/cxf/rest/",
  NEXT_PUBLIC_DIRECTUS_NO_COLLECTION: "https://directus-dev.prometeon.com/website/",
  NEXT_PUBLIC_SENTRY_DSN:
    "https://c9c79450f4e345ef90ab7d6d516145a0@o445783.ingest.sentry.io/5423017",
  SENTRY_ORG: "prometeon",
  SENTRY_PROJECT: "prometeon-website",
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  NEXT_PUBLIC_GOOGLE_API_KEY: "AIzaSyCTfm7cU3zPMx72ODXRJIhq3QWhPNOXbCQ",
  GOOGLE_CAPTCHA_SITE_KEY: "6LeCQSkaAAAAALomZILjz4bInutWScXQyEVkGsJA",
  GOOGLE_CAPTCHA_SECRET_KEY: "6LeCQSkaAAAAADCZfIA9cAviRFs9dDNKCNKUuOxx",
};