import React, { useContext } from 'react';
import { useRouter } from 'next/dist/client/router';

import { isLocale, mergeLocale, arabicFont } from '../locale/helpers';

export const LocaleContext = React.createContext({
  locale: [],
  setLocale: () => null,
  locales: [],
  regions: [],
  isRtl: false,
});

export const LocaleProvider = ({ loc, locales, regions, children }) => {
  const [locale, setLocale] = React.useState(loc);
  const [isRtl, setIsRtl] = React.useState(false);
  const { query } = useRouter();

  // rtl direction
  const router = useRouter();
  const { route } = router;

  React.useEffect(() => {
    if (locale) {
      setIsRtl(arabicFont(route?.slice(18), locale?.slice(3, 8)));
    }
  }, [locale]);

  // store the preference
  React.useEffect(() => {
    if (locale && locale !== localStorage.getItem('locale')) {
      localStorage.setItem('locale', locale);
    }
  }, [locale]);

  // sync locale value on client-side route changes
  React.useEffect(() => {
    if (
      typeof query.country === 'string' &&
      typeof query.lang === 'string' &&
      isLocale(mergeLocale(query.country, query.lang), locales) &&
      locale !== mergeLocale(query.country, query.lang)
    ) {
      setLocale(mergeLocale(query.country, query.lang));
    }
  }, [query.country, query.lang, locale]);
  return (
    <LocaleContext.Provider value={{ locale, locales, regions, setLocale, isRtl }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
